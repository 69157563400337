<template>
  <b-container>
    <b-row style="width:100% !important">
      <fill-form
        :pre-populate="prePopulate"
        :form-name="formName"
        @callback="callback"
      />
    </b-row>
  </b-container>
</template>

<script>
import FillForm from '@/views/components/formbuilder/FillForm.vue'

export default {
  name: 'NewPatient',
  components: { FillForm },
  data() {
    return {
      formName: 'Registration Form',
      visible: false,
      prePopulate: {},
    }
  },
  methods: {
    callback(value) {
      const id = value.document.name
      // this.$router.push({ name: "patient-view-actions", params: { id } });
    },
  },
}
</script>

<style></style>
