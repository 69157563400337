<template>
  <div>
    <b-row
      style="width:100%;margin-right:0px !important; width:100%;"
      class="mx-0 w-100"
    >
      <div
        class="career-header"
        style="width:100%"
      >

        <b-row
          class="auth-inner m-0"
          style="width:100%"
        >
          <!-- Brand logo-->
          <b-col
            cols="6"
            style="width:50%"
          >
            <b-link
              class="brand-logo row ml-2 mt-2"
              style="color:dark-blue;"
              href="/career"
            >
              <vuexy-logo />
              <h2 class="brand-text  ml-1 title-heading-color title-logo">
                Ministry of Health
              </h2>
            </b-link>
          </b-col>

          <!-- /Brand logo-->

          <!-- Left Text-->
          <b-col
            class="pull-right"
            style="width:50%"
            cols="6"
          >
            <div
              class="pull-right"
              style="float:right"
              @click=" navigateToPath()"
            >
              <user-account
                id="tooltip-button-show-event"
                class="mx-3 mt-2"
              />
              <b-tooltip
                ref="tooltip"
                target="tooltip-button-show-event"
              >
                <span v-if="isLoggedIn"> Open <strong>  profile  </strong></span>
                <span v-else> Click to <strong>  Login </strong></span>
              </b-tooltip>
            </div>
          </b-col>
          <!-- /Left Text-->
        </b-row>
      </div>
    </b-row>
    <b-row> <job-list class="mx-4-md mx-4-lg  mx-4-sm" /></b-row>
    <b-container />
  </div>
</template>
<script>
import {
  BContainer, BRow, BLink, BTooltip,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { isUserLoggedIn } from '@/auth/utils'
import UserAccount from './UserAccount.vue'
import JobList from './JobList.vue'

export default {
  components: {
    BContainer,
    BRow,
    JobList,
    BLink,
    VuexyLogo,
    UserAccount,
    BTooltip,
  },
  data() {
    return { isLoggedIn: null }
  },
  created() {
    this.isLoggedIn = isUserLoggedIn()
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'auth-login' })
    },
    openDashboard() {
      this.$router.push({ name: 'career-profile' })
    },
    navigateToPath() {
      if (this.isLoggedIn) {
        this.openDashboard()
      } else {
        this.goToLogin()
      }
    },
  },

}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
 .career-header{
   background: url('https://portal.MOH.go.ke/files/banner_MOH.jpeg');
   background-color: #cccccc;
   height:300px;
   background-repeat: no-repeat;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   width: 100% !important;
   margin-right: 0px !important;
 }
 .title-heading-color {
    color: #0a367b;
    background: rgba(255,255,255,0.5);
    height: 29px;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: 900;
 }
@media only screen and (max-width: 600px) {
   .title-logo{
    font-size: 12px;
   }
}

</style>
